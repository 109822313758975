var nickname = "";

	function continueAsGuest(){
		document.getElementById("signInSection").style.display="none";
		document.getElementById("enterNameSection").style.display="block";
	}

	var goBack = function(){
		document.getElementById("signInSection").style.display="block";
		document.getElementById("enterNameSection").style.display="none";
	}
	var login = function(){
		document.getElementById("menuPage").style.display="none";
		document.getElementById("gamePage").style.display="block";
		
		nickname = document.getElementById("usernameInput").value;
		document.getElementById("usernameDisplay").innerHTML = nickname;
	}
	var toggleSearchBar = function(){
		if (document.getElementById("searchBar").style.display=="none"){
			document.getElementById("searchBar").style.display="block";
			document.getElementById("searchButton").style.display="none";
			document.getElementById("closeButton").style.display="inline";
		}
		else{
			document.getElementById("searchBar").style.display="none";
			document.getElementById("searchButton").style.display="inline";
			document.getElementById("closeButton").style.display="none";
		}
	}
	
	var keyPressedUp, keyPressedDown, keyPressedLeft, keyPressedRight;
	document.onkeydown = function(event){
        if(event.keyCode === 87) //W
			keyPressedUp = true;
        if(event.keyCode === 83) //S
			keyPressedDown = true;
		if(event.keyCode === 65) //A
			keyPressedLeft = true;
		if(event.keyCode === 68) //D
			keyPressedRight = true;
    }
	
	document.onkeyup = function(event){
        if(event.keyCode === 87) //W
			keyPressedUp = false;
        if(event.keyCode === 83) //S
			keyPressedDown = false;
		if(event.keyCode === 65) //A
			keyPressedLeft = false;
		if(event.keyCode === 68) //D
			keyPressedRight = false;
    }
	
	
	var selfId = 0; 
	function playerController() {
		if (keyPressedUp && playerList[selfId].y >= window.innerHeight - document.getElementById(selfId).clientHeight/2 -40) playerList[selfId].speedY -= 20;
		if (keyPressedDown) playerList[selfId].y += 6;
		if (keyPressedLeft) playerList[selfId].speedX = -10;
		if (keyPressedRight) playerList[selfId].speedX = 10;
	}
	
	var mouseX,mouseY;
	onmousemove = function(e){
		mouseX = e.clientX;
		mouseY = e.clientY;
	}
	
	
	//If dragPlayer == false, cursor is not dragging anything
	//If dragPlayer == An unique id, cursor is dragging that id
	var dragPlayer = false;
	var clickPlayerHandler = function(state,id){
		if (reactionCursor == false){
			if (state == false) playerList[id].speedY -= 6;
		}
		if (reactionCursor == "reactionHand"){
			if (state == false) dragPlayer = false;
			else dragPlayer = id;
		}
		else if (state == true)
			doReaction(id);
	}
	
	var doReaction = function(id){
		if (reactionCursor == "reactionHammer"){
			var newCanvas = `<canvas id=${"explosion-"+id} width="300" height="300" style="position:fixed"></canvas>`;
			document.getElementById("explosionCanvas").insertAdjacentHTML("beforeend", newCanvas);
			
			var object = document.getElementById("explosion-"+id);
			object.style.top = playerList[id].y - object.clientHeight/2 + "px";
			object.style.left = playerList[id].x - object.clientWidth/2 + "px";
			
			var explode = new rive.Rive({
				src: window.explode,
				canvas: document.getElementById("explosion-"+id),
				autoplay: true,
			});
			
			
			setTimeout(function(){
				for (var i in playerList){
					playerList[i].speedY -= 15 +15*Math.random() ;
					playerList[i].speedX += 5 - 10*Math.random() ;
				}
			}, 300);
			
			
			setTimeout(function(){object.remove();}, 1300); //Remove animation
		}
		else if (reactionCursor == "reactionHeart"){
			playerList[id].speedY -= 25; //Remove animation
		}
	}
	
	var gravity = 1; //0.1 - 1
	var physicSystem = function(){
		for (var id in playerList){
			
			playerList[id].y += playerList[id].speedY;
			playerList[id].x += playerList[id].speedX;
			
			if (playerList[id].y >= window.innerHeight - document.getElementById(id).clientHeight/2-40){ //Bound
				playerList[id].speedY = 0;
				playerList[id].speedX = 0;
				playerList[id].y = window.innerHeight - document.getElementById(id).clientHeight/2-40;
			}
			if (playerList[id].y <= 0 + document.getElementById(id).clientHeight/2){
				playerList[id].speedY = 0;
				playerList[id].y = 0 + document.getElementById(id).clientHeight/2;
			}
			
			if (playerList[id].x >= window.innerWidth - document.getElementById(id).clientWidth/2){
				playerList[id].speedX = 0;
				playerList[id].x = window.innerWidth - document.getElementById(id).clientWidth/2;
			}
			if (playerList[id].x <= 0 + document.getElementById(id).clientWidth/2){
				playerList[id].speedX = 0;
				playerList[id].x =0 + document.getElementById(id).clientWidth/2;
			}
			
			if (playerList[id].y <= window.innerHeight - document.getElementById(id).clientHeight/2-40){ //Gravity
				playerList[id].speedY += gravity;
			}
			
			if (dragPlayer !== false){ //Drag Physics
				playerList[dragPlayer].x = mouseX;
				playerList[dragPlayer].y = mouseY;
				playerList[dragPlayer].speedY = 0;
				playerList[dragPlayer].speedX = 0;
			}
		}
	}
	
	function drawController() {
		for (var id in playerList){
			var object = document.getElementById(id);
			object.style.top = playerList[id].y - object.clientHeight/2 + "px";
			object.style.left = playerList[id].x - object.clientWidth/2 + "px";
		}
	}
	
	var playerList = {};

	
	var reactionCursor = "reactionHand";
	function changeReaction(result){
		reactionCursor = result;
		//if (reactionCursor == false)
		//	document.body.style.cursor = "pointer";
		//else
		//	document.body.style.cursor = "none";
	}
	
	function reactionHandler(){
		document.getElementById("reactionHand").style.display = "none";
		document.getElementById("reactionHeart").style.display = "none";
		document.getElementById("reactionHammer").style.display = "none";
		
		if (reactionCursor !== false){
			document.getElementById(reactionCursor).style.display = "block";
			
			document.getElementById("reactionCursor").style.top = (mouseY - document.getElementById(reactionCursor).clientHeight/2) + "px";
			document.getElementById("reactionCursor").style.left = (mouseX - document.getElementById(reactionCursor).clientWidth/2) + "px";
		}
	}
	
	//Jump To Game directly
	continueAsGuest();
	document.getElementById("usernameInput").value = "";
	login();
	
	function toggleSettings(){
		if (document.getElementById("settingDiv").style.display == "none")
			document.getElementById("settingDiv").style.display = "block";
		else
			document.getElementById("settingDiv").style.display = "none";
	}
	
	function changeGravity(number){
		if (number >= 0.05 && number <= 3)
			gravity = number;
	}
	
	var settingsType = "image";
	function changeSettings(type){
		settingsType = type;

		document.getElementById("dataInput-image").style.display = "none";
		document.getElementById("dataInput-color").style.display = "none";
		document.getElementById("dataInput-gravity").style.display = "none";

		document.getElementById("dataInput-"+type).style.display = "inline-block";
	}

	function resetSettings(){
		changeBackground("image"," ");
		changeBackground("color"," ");
		changeGravity(1);

		toggleSettings();
	}

	function submitSettings(){
		if (settingsType == "image" || settingsType == "color")
			changeBackground(settingsType,document.getElementById('dataInput-'+settingsType).value);
		else if (settingsType == "gravity")
			changeGravity(parseFloat(document.getElementById('dataInput-gravity').value));
		
		toggleSettings();
	}
	
	function changeBackground(type,data){
		if (type == "image"){
			if (data == "")
				data = "https://www.planetware.com/wpimages/2020/02/france-in-pictures-beautiful-places-to-photograph-eiffel-tower.jpg";
			document.getElementById("gameCanvasBackground").style.backgroundImage = `url('${data}')`;
			document.getElementById("gameCanvasBackground").style.backgroundColor = "";
		}
		else if (type == "color"){
			if (!data)
				data = "#262626";
			document.getElementById("gameCanvasBackground").style.backgroundImage = "";
			document.getElementById("gameCanvasBackground").style.backgroundColor = data;
		}
		else if (type == "twitch"){
		}
	}
	
	if (/gravity=max/.test(window.location.href))
		changeGravity(3);
	else if (/gravity=min/.test(window.location.href))
		changeGravity(0.1);
	
	window.addEventListener('message', event => {
		// IMPORTANT: check the origin of the data! 
		if (event.origin.startsWith('https://playground.avatech.ai')) { 
			if (event.data.call == "setGravity"){
				if (event.data.value)
					changeGravity(3);
				else
					changeGravity(0.1);
			}
				
		} else {
			 //The data was NOT sent from your site! 
			 //Be careful! Do not use it. This else branch is
			 //here just for clarity, you usually shouldn't need it.
			return; 
		} 
	},false); 
	
	function animate(){
		requestAnimationFrame(animate);
		physicSystem();
		playerController();
		reactionHandler();
		drawController();
	}
	animate();